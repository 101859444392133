import { ApprenticeAuth } from '@/components/shared/Apprentice/ApprenticeAuth'
import { Loading } from '@/components/shared/basic/Loading'
import { ShouldRender } from '@/components/shared/misc'
import ZendeskProvider from '@/providers/shared/ZendeskProvider'
import { isLoggedIn } from '@/utils/lib'
import { ApprenticeProfile as User } from '@qureos/types'
import { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { user } from 'src/atoms'
import { ProfileProvider } from 'src/providers/shared'
import { UserRole } from 'src/types/shared/graphql'
import { loginRedirect } from '@qureos/lib'

const { Admin, Student } = UserRole

const Home: NextPage = () => {
  const router = useRouter()
  const apprenticeInstantJobId = router?.query?.apprenticeInstantJobId
  const auth = router.query.key ? (router.query.key as string) : undefined
  const refresh = router.query.ref
  const [loading, setLoading] = useState<boolean>(true)
  const profile = useRecoilValue<User>(user)
  const redirectUrl = loginRedirect('get')

  useEffect(() => {
    if (isLoggedIn()) {
      handleRedirect()
    }
  }, [profile])

  useEffect(() => {
    if (refresh) {
      if (window) {
        window.history.pushState({}, '', `/`)
        window.location.reload()
      }
    }
  }, [refresh])

  const handleRedirect = () => {
    if (profile) {
      const { role, email, verified } = {
        ...profile
      }
      let nextRoute = '/home'
      if (role === Student) {
        const {
          meta: { onboardingStep }
        } = { ...profile }

        switch (true) {
          case !verified:
            nextRoute = `/verify-apprentice?operation=${encodeURIComponent(email)}`
            break
          case !profile.birthdate:
            nextRoute = '/verify-birthday'
            break
          case !onboardingStep || onboardingStep <= 2:
            nextRoute = `/apprentice-onboarding${
              apprenticeInstantJobId
                ? `?apprenticeInstantJobId=${apprenticeInstantJobId}`
                : ''
            }`
            break
          case !!redirectUrl:
            nextRoute = null
            break
          default:
            nextRoute = '/jobs'
        }
      }

      if (role === Admin) {
        nextRoute = '/admin/dashboard'
      }

      if (!auth && nextRoute) {
        router.push(nextRoute)
      }
    }
  }

  return (
    <ProfileProvider
      onCompleted={handleRedirect}
      onError={() => setLoading(false)}
    >
      <ZendeskProvider />
      <ShouldRender check={loading}>
        <div slot="fallback">
          <Loading />
        </div>
      </ShouldRender>
      <ShouldRender check={!loading}>
        <ApprenticeAuth />
      </ShouldRender>
    </ProfileProvider>
  )
}
export async function getServerSideProps(context) {
  const { locale } = context

  return {
    props: {
      ...(await serverSideTranslations(locale))
    }
  }
}
export default Home
